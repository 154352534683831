#table-wrapper {
  margin-top: 10px;
  width: 100%;

  position: relative;
}

.table table {
  width: 100%;
}

.table th,
td {
  width: 16%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table thead {
  display: block;
  height: 40px;
  align-items: center;
  text-align: left;
}

.table tbody {
  display: block;
  overflow: hidden auto;
}

.table thead tr {
  font-family: "Rubik-Medium";
  font-size: 19px;
}

.table tbody tr {
  align-items: center;

  padding-top: 10px;
  padding-bottom: 10px;

  font-family: "Rubik-Regular";
  font-size: 16px;
}

.table tr {
  position: relative;
  display: flex;
  justify-content: space-around;

  overflow: hidden;
}

.table tr strong {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-all;
}

tbody tr:nth-child(odd) {
  background: #f4f4f4;
}

.table .line {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 2px;
  margin-left: 14.5%;
  margin-top: -10px;
  z-index: 1;
}

@media screen and (max-width: 800px) {

  .table th,
  td {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .table .strong {
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Rubik-bold";
  }

  .table .special {
    font-size: 17px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .table .sub-div {
    background-color: transparent;
  }

  .table .sub-tr {
    margin-left: 20%;
    width: 80%;
    background-color: transparent;

    text-align: left;
    justify-content: flex-start;
  }

  .special-div:nth-child(even) {
    background: #f4f4f4;
  }

  .special-div:nth-child(odd) {
    background: white;
  }

  .special-div .special {
    background-color: transparent !important;
  }
}

/* #region spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 4px;
  border: 4px solid #bababa;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #bababa transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* #endregion */