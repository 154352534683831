.auction-page-background {
  margin: 50px;
  margin-left: 10%;
  margin-right: 10%;

  display: flex;
  flex-direction: row;

  gap: 5%;
}

.auction-page-background .photos {
  flex: 1;
}

.auction-page-background .photo-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;

  padding: 25px;

  overflow: none;
}

.auction-page-background .photo {
  aspect-ratio: 1;
  width: 100%;
}

.auction-page-background .informations {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;

  align-self: flex-start;
  position: sticky;
  top: 132px;
}

.auction-page-background .bid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-top: 10px;

  padding: 15px;
  padding-left: 10px;
  padding-right: 10px;

  gap: 10px;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12.72px;

  width: calc(100% - 20px);
}
.auction-page-background .bid div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auction-page-background .user {
  background-color: #b3b3b3;
  aspect-ratio: 1;
  border-radius: 360px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auction-page-background hr {
  height: 2px;
  width: 100px;
  margin: 0;
  margin-top: 6px;
  border-width: 0;
  background-color: #17161a;
}

.auction-page-background .informations .sub-title {
  font-family: "Rubik-Medium";
  font-size: 21px;
  color: #17161a;

  margin-top: 20px;
}

.auction-page-background .informations #offer-input {
  position: relative;
  margin-top: 10px;
  width: 100%;
}
.auction-page-background .informations #offer-input .icon {
  position: absolute;

  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
}

.auction-page-background .informations #offer-input button {
  position: absolute;

  top: 0;
  right: 0;

  height: 100%;

  padding-left: 40px;
  padding-right: 40px;

  background: #d02224;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12.72px;

  outline: none;
  border: none;

  color: #ffffff;
  font-family: "Rubik-Bold";
  font-size: 21px;

  transition-duration: 100ms;
}

.auction-page-background .informations #offer-input button:active {
  transform: translate(5px, 0);
}

.auction-page-background .informations #offer-input input {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12.72px;

  border: none;
  outline: none;
  height: 65px;
  width: calc(100% - 40px);

  padding: 0;
  padding-left: 40px;

  font-family: "Rubik-Bold";
  font-size: 18px;
}

.auction-page-background .informations .info-box {
  flex: 1;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 38.77px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 25px;

  gap: 3px;
}

.auction-page-background .informations .info-title {
  font-family: "Rubik-Medium";
  font-size: 17px;
  color: #6a696c;
}

.auction-page-background .informations .description-text {
  font-family: "Rubik-Bold";
  font-size: 20px;

  color: #17161a;
}

.auction-page-background .title {
  font-family: "Rubik-Bold";
  font-size: 27px;

  margin-top: 15px;
}

.auction-page-background .informations .status {
  padding: 6px;
  padding-top: 4px;
  padding-bottom: 4px;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 60px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 5px;

  font-family: "Rubik-Regular";
  font-size: 14px;
}
.auction-page-background .informations .status .status-balloon {
  aspect-ratio: 1;
  width: 13px;
  height: 13px;

  border-radius: 360px;
}

.auction-page-background img {
  width: 100%;
  aspect-ratio: 1;
  filter: drop-shadow(0px 1.5px 15px rgba(0, 0, 0, 0.2));
  border-radius: 38.77px;
}

@media screen and (max-width: 950px) {
  .auction-page-background {
    margin: 30px;
    margin-left: 0;
    margin-right: 0;

    gap: 25px;

    flex-direction: column;
  }

  .auction-page-background .informations {
    padding-left: 5%;
    padding-right: 5%;

    width: 90%;

    position: static;
  }

  .auction-page-background .photo-content {
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    gap: 25px;

    padding: 25px;

    overflow-x: hidden;
  }
  
  .auction-page-background .photos {
    flex: 1;
    overflow: auto hidden;
  }

  .auction-page-background img {
    height: 65vw;
    max-height: 400px;

    aspect-ratio: 1;
    filter: drop-shadow(0px 1.5px 15px rgba(0, 0, 0, 0.2));
    border-radius: 38.77px;
  }
}
