.loading-background {
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;

  z-index: 10002;

  animation: start-anim 250ms;

  background-color: rgba(0, 0, 0, 0.155);

  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 15px;

  display: flex;
  flex-direction: column;

  animation: start-anim-box 250ms;

  gap: 15px;
}

.loading-lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #6a696c;
  border-radius: 50%;
  animation: loading-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #6a696c transparent transparent transparent;
}
.loading-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
