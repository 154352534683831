.slider-view-box {
  width: 100%;
  overflow: hidden hidden;
  height: auto;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.slider-view-box .selectors {
  width: 100%;
  height: 35px;
  margin-top: -35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 8px;

  position: absolute;

  transition-duration: 250ms;
}

.slider-view-box .selectors .SlideButton {
  height: 8px;
  width: 8px;

  background-color: antiquewhite;

  transition-duration: 750ms;

  border-radius: 360px;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.slider-content-box {
  width: max-content;
  display: flex;
  flex-direction: row;

  position: relative;

  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  -ms-user-select: none;

  transition-duration: 500ms;
}

.slider-content-box img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  -ms-user-select: none;
}