.Navbar {
  position: sticky;
  top: -2px;
  width: 100%;
  height: 90px;

  /* White */
  background: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 998;
}

.HeaderContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: relative;
  top: 0;
  width: 100%;
  height: 90px;
}

.Logo {
  transition-duration: 250ms;
  position: relative;
  height: 70px;
  width: auto;
}

.MenuItems {
  display: inline-flex;
  margin: 5%;

  list-style: none;
}

.MenuItems li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MenuButtons {
  display: inline-flex;
  gap: 20px;
  white-space: nowrap;

  justify-content: center;
  align-items: center;

  background-color: transparent;
  outline: none;
  border: none;
}

.MenuButtons .user {
  background-color: #b3b3b3;
  aspect-ratio: 1;
  border-radius: 360px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TextStyle {
  transition-duration: 250ms;
  color: #17161a;
  white-space: nowrap;

  font-family: "Rubik-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
}

.Navbar a {
  transition-duration: 250ms;
  color: #17161a;
  white-space: nowrap;

  font-family: "Rubik-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
}

.Navbar a:hover {
  transition-duration: 100ms;
  color: #6a696c;
  background-color: transparent "!important";
}

.NavlinkButton {
  margin-top: auto;
  margin-bottom: auto;
  height: 35px;
  width: 115px;

  padding: 0;

  border-radius: 12.8px;

  transition-duration: 150ms;
}
.NavlinkButton:hover {
  scale: 1.1;
  transition-duration: 150ms;
}

#register {
  font-family: "Rubik-Bold";
  font-size: 16px;

  border: 2.5px solid #d02224;
  background: #d02224;

  color: white !important;
}

#login {
  font-family: "Rubik-Bold";
  font-size: 16px;

  border: 2.5px solid #d02224;
  background: white;
  color: #d02224;
}

.Menu {
  transition-duration: 250ms;

  display: flex;
  background-color: white;

  position: absolute;
  top: 100px;

  border-radius: 27.816px;

  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  padding: 5px;
  padding-top: 20px;
  padding-bottom: 20px;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.Menu a {
  text-align: left;
  font-size: 14px;
}

.Navbar hr {
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
  height: 1px;
  border-width: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.Icon {
  transition-duration: 250ms;
}

.MenuItem {
  transition-duration: 250ms;
  color: #17161a;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  border: none;
  background: transparent;

  font-family: "Rubik-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
}

.ResponsiveMenuItems {
  display: none;
}

.ResponsiveMenu {
  display: none;
}

.ResponsiveLowerMenuItems {
  margin-left: 30px;
}

.Quit {
  display: block;
  position: fixed;

  top: 0;

  width: 100%;
  height: 100%;

  margin-right: 260px;

  z-index: 1000;
}

.Decore {
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;

  background: rgba(208, 34, 36, 1);
}

.ResponsiveName {
  display: none;
}

@media screen and (max-width: 1230px) {
  .HeaderContent {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }
  .MenuItems {
    display: none;
  }
  .MenuButtons {
    display: none;
  }
  .ResponsiveMenuItems {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding: 0px;

    width: 100%;
    height: 90px;
  }
  .ResponsiveMenu {
    display: block;
    position: fixed;

    transition-duration: 250ms;

    top: 0;

    width: 260px;
    height: 110%;

    background: rgba(0, 0, 0, 0.9);
    z-index: 999;
  }
  .ResponsiveMenuContentTop {
    width: 84%;
    height: 60%;

    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;
    padding: 0px;

    margin-top: 50px;
    margin-left: 8%;
  }
  .ResponsiveMenuContentTop li {
    list-style: none;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .ResponsiveMenuContentTop a {
    color: white;
    font-size: 18px;
  }
  .ResponsiveMenuContentTop a:hover {
    color: dimgray;
  }

  .ResponsiveMenuContentBottom {
    width: 100%;
    height: 30%;

    gap: 5px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px;
  }
  .ResponsiveName {
    display: block;
    font-family: "Rubik-Bold";
    font-size: 18px;

    /* Sola Dayalı yapmak için alttaki iki satırın rollerini değiştirin*/
    position: absolute;
    /*padding-left: 10px;*/

    white-space: nowrap;
  }
  .NavlinkButton {
    margin-top: 0px;
    margin-bottom: 5px;

    margin-right: auto;
    margin-left: auto;
    width: 70%;

    padding: 0;

    border-radius: 9px;

    transition-duration: 150ms;
  }
  .NavlinkButton:hover {
    scale: 1.1;
    transition-duration: 150ms;
  }

  #register {
    font-family: "Rubik-Bold";
    font-size: 16px;

    border: 2.5px solid #d02224;
    background: #d02224;

    color: white !important;
  }

  #login {
    font-family: "Rubik-Bold";
    font-size: 16px;

    border: 2.5px solid #d02224;
    background: white;
    color: #d02224;
  }
}
