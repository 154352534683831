.slider-place-holder {
  width: 100%;

  margin-top: 30px;
  margin-bottom: 30px;

  gap: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-place-holder .title {
  font-family: "Rubik-Bold";
  font-size: 20px;
  color: #17161a;
}

.slider-place-holder .viewport {
  width: 100%;
  overflow: hidden;
}

.slider-place-holder .content {
  display: flex;
  flex-direction: row;
  transition-duration: 200ms;
  overflow: visible;
}

.slider-place-holder .item {
  display: flex;

  width: 100%;
  height: initial;
  align-items: center;

  justify-content: center;

  overflow: visible;

  transition-duration: 200ms;
}

.slider-place-holder .see-all {
  transition-duration: 250ms;

  background: #d02224;
  color: white !important;

  border-radius: 15px;

  padding: 15px;
  padding-top: 7px;
  padding-bottom: 7px;

  font-family: "Rubik-Medium";

  font-size: 21px;

  border: none;
}

.slider-place-holder .see-all-bottom {
  transition-duration: 250ms;

  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;

  width: 225px;
  height: 32px;

  white-space: nowrap;

  background: #d02224;
  border-radius: 12.72px;

  font-family: "Rubik-Medium";

  font-size: 18px;
  color: white !important;

  border: none;
}
.slider-place-holder .see-all:hover,
.see-all-bottom:hover {
  background-color: white;
  color: #d02224 !important;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
}

.slider-place-holder .selectors {
  width: 100%;
  height: 25px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 8px;

  transition-duration: 250ms;
}

.slider-place-holder .selectors button {
  height: 8px;
  width: 8px;

  transition-duration: 250ms;

  background-color: white;

  border-radius: 360px;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 1100px) {
  .slider-place-holder .title {
    margin-bottom: 10px;
  }
}
