.Error500Page {
  width: 60%;
  height: auto;

  margin-top: 8%;
  margin-bottom: 8%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
}
.Error500Page li {
  font-family: "Rubik-Medium";
  font-size: 2.5vw;

  white-space: nowrap;
  list-style: none;
}
.Error500Page .Small {
  font-family: "Rubik-Regular";
  font-size: 1.25vw;
}
.Error500Page .Icon {
  margin-bottom: 4%;

  width: 80%;
  height: auto;
  aspect-ratio: 100/37;
}
.Error500Page .MainMenu {
  font-family: "Rubik-Bold";
  width: 18%;
  height: auto;
  aspect-ratio: 5/1.4;

  margin-top: 4%;

  min-width: 150px;
  font-size: 25px;
  white-space: nowrap;
  color: white;

  background: #17161a;
  border-radius: 1.2vw;
  border-width: 0;

  transition-duration: 100ms;
}
.Error500Page .MainMenu:hover {
  background-color: #d02224;
  transition-duration: 100ms;
}
