.contract-area {
  width: 100%;
}

.contract-area h1 {
  font-family: "Rubik-Bold";
  font-size: 26px;
  color: #17161a;
}

.contract-area h2 {
  padding-top: 20px;
  font-family: "Rubik-Medium";
  font-size: 20px;
  color: #17161a;
}

.contract-area p {
  font-family: "Rubik-Regular";
  font-size: 14px;
  color: #6a696c;
}

.contract-area li {
  padding-bottom: 5px;
  font-family: "Rubik-Regular";
  font-size: 14px;
  color: #6a696c;
}

.contract-area #NavLink {
  font-family: "Rubik-Bold";
  font-size: 18px;
  color: white;

  width: min-content;

  margin-top: 25px;

  padding: 4px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #17161a;
  border-radius: 8px;
  border: none;

  transition-duration: 250ms;
}
.contract-area #NavLink:hover {
  background-color: #6a696c;
  filter: drop-shadow(0px 3px 30px rgba(0, 0, 0, 0.2));
}

@media screen and (max-width: 900px) {
  .contract-area h1 {
    text-align: center;
  }
  .contract-area h2 {
    text-align: center;
  }
  .contract-area #NavLink {
    width: 100%;

    padding-left: 0;
    padding-right: 0;
  }
}
