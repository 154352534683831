.users-table {
  margin-top: 20px;

  margin-left: 8%;
  margin-right: 8%;
  width: 84%;
}

.users-table hr {
  margin: 0;
  margin-bottom: 5px;
  margin-left: 6px;

  height: 1.5px;
  border-width: 0;
  background-color: #17161a;
}

.users-table .table-background {
  height: max-content;
  padding-bottom: 25px;
  margin-top: 25px;
  margin-bottom: 25px;

  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 38px;

  overflow: hidden;

  list-style: none;

  overflow: hidden;
}

.users-table .table-background .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;

  margin-left: 20px;
  margin-right: 20px;

  height: 70px;

  background-color: aquamarine;
}

.users-table .search-area .selected-feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  color: #17161a;

  font-size: 16px;
  font-family: "Rubik-Regular";
  white-space: nowrap;

  border: none;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 5px;
}

.users-table .search-area .selected-feature:active {
  transform: none;
}

.users-table .search-area .state-menu {
  position: absolute;

  /* margin-top: 220px; in typescript */
  margin-left: -15px;

  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  border-radius: 8px;
  padding: 8px;

  display: flex;
  flex-direction: column;

  text-align: left;

  gap: 10px;

  z-index: 990;
  transition-duration: 150ms;
}

.users-table .search-area .state-menu button {
  border: none;
  background-color: transparent;

  font-size: 18px;
  font-family: "Rubik-Regular";
}

.users-table .search-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1%;
}

.users-table .search-area .search-bar {
  width: 30%;
  display: flex;

  transition-duration: 150ms;
}

.users-table .search-area .search-bar button {
  position: absolute;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;
}

.users-table .search-area .search-bar input {
  height: 28px;
  width: 100%;

  font-family: "Rubik-Medium";

  border-radius: 8px;
  border: none;

  padding-left: 28px;
  padding-right: 0;

  background: #f4f4f4;
  color: #17161a;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  text-align: left;

  transition-duration: 150ms;
}

.users-table .search-area .search-bar input:focus-visible {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border: none !important;

  outline: none;

  transition-duration: 250ms;
}

@media screen and (max-width: 1000px) {
  .users-table .search-area {
    gap: 12px;
  }

  .users-table .search-area .search-bar {
    width: 65%;
  }
}

@media screen and (max-width: 800px) {
  .users-table .table-background {
    padding-bottom: 0;
  }
}