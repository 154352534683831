/* Generic */
.Footer-BackGround {
  width: 100%;
  height: 500px;
  background-color: #17161a;
}
.Footer-Top {
  height: 90%;
  margin-left: 10%;
  margin-right: 10%;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.Footer-Bottom {
  height: 10%;
  margin-left: 10%;
  margin-right: 10%;

  flex-direction: column;

  list-style: none;
}

/* Made By*/
.Footer-Bottom .MadeBy {
  width: 96%;
  display: inline-flex;
  flex-direction: row;

  justify-content: space-between;
  color: white;

  margin-left: 2%;
  margin-right: 2%;

  vertical-align: middle;
  white-space: nowrap;
}
.Footer-Bottom .MadeBy div {
  display: flex;
  flex-direction: row;
}
.Footer-Bottom .MadeBy div li a {
  font-family: "Rubik-Medium";
  padding: 0;
  font-size: 15px;
  color: white;
}
.Footer-Bottom hr {
  height: 1px;
  border-width: 0;
  background-color: white;
  margin-top: 0;
}

/* Institutional */
.Footer-Top .Institutional {
  width: 30%;
  height: 90%;

  flex-direction: column;
  color: white;
  font-size: 17px;
  font-family: "Rubik-Regular";
}
.Institutional .Footer-Logo {
  width: 25%;
}
.Institutional div {
  margin-left: 5px;
  margin-right: 30%;
  margin-top: 4%;
}

/* QuickLinks */
.Footer-Top .QuickLinks {
  width: 20%;
  height: 90%;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  color: white;
  font-family: "Rubik-Regular";
  font-size: 16px;
  font-weight: 400;

  list-style: none;
  white-space: nowrap;
}
.QuickLinks li a {
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.QuickLinks li a:hover {
  color: #6a696c;
}
.QuickLinks .Title {
  font-size: 21px;
  padding-left: 12px;
}

/* Communication */
.Footer-Top .Communication {
  width: 35%;
  height: 90%;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  color: white;
  font-family: "Rubik-Regular";
  font-size: 16px;
  font-weight: 400;

  list-style: none;
}
.Communication li a {
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;

  flex-direction: row;
  align-items: flex-start;
}
.Communication li a:hover {
  color: #6a696c;
}
.Communication .Title {
  font-size: 21px;
  padding-left: 12px;
}

/* SocialMedia */
.Footer-Top .SocialMedia {
  width: 15%;
  height: 90%;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  color: white;
  font-family: "Rubik-Regular";
  font-size: 16px;
  font-weight: 400;

  list-style: none;

  white-space: nowrap;
}
.SocialMedia li a {
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;

  flex-direction: row;
  align-items: flex-start;
}
.SocialMedia li a:hover {
  color: #6a696c;
}
.SocialMedia .Title {
  font-size: 21px;
  padding-left: 12px;
}

/* Generic Multiples */
.QuickLinks hr,
.Communication hr,
.SocialMedia hr {
  margin-left: 12px;
  width: 29px;
  height: 2px;
  border-width: 0;
  background-color: white;
}
.QuickLinks .Icon,
.Communication .Icon,
.SocialMedia .Icon {
  padding-right: 5px;
}

.Footer-BackGround .Hr {
  display: none;
}

@media screen and (max-width: 800px) {
  /* Generic */
  .Footer-BackGround {
    height: auto;
  }
  .Footer-Top {
    height: auto;
    flex-direction: column;
  }
  .Footer-Bottom {
    margin-top: 2%;
    height: 50px;
  }

  /* Made By */
  .Footer-Bottom .MadeBy {
    display: flex;
    flex-direction: column;

    font-size: 10px;
    text-align: center;
  }
  .Footer-Bottom .MadeBy div {
    flex-direction: column;
  }
  .Footer-Bottom .MadeBy div li a {
    font-size: 10px;
  }

  /* Institutional */
  .Footer-Top .Institutional {
    width: 100%;
    height: auto;

    text-align: center;

    align-items: center;
    padding-top: 5%;
  }
  .Institutional div {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 4%;
  }

  /* Quick Links*/
  .Footer-Top .QuickLinks {
    width: 100%;
    height: auto;

    justify-content: center;
    align-items: center;
  }

  /* Communication */
  .Footer-Top .Communication {
    width: 100%;
    height: auto;

    align-items: center;
    text-align: center;
  }
  .Communication li a {
    align-items: center;
  }

  /* SocialMedia */
  .Footer-Top .SocialMedia {
    width: 100%;
    height: auto;

    align-items: center;
  }

  /* Mulitple Generic */
  .Footer-BackGround .Hr {
    display: block;
    width: 40%;
    height: 2px;
    border-width: 0;
    background-color: white;

    margin-top: 10%;
    margin-bottom: 10%;
  }
  .QuickLinks hr,
  .Communication hr,
  .SocialMedia hr {
    margin-left: auto;
  }
  .Footer-BackGround .Title {
    padding-left: 0;
  }
}
