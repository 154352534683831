.AboutUs-background {
  /* width: 70%; */
  height: auto;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  /* margin-right: 15%;
    margin-left: 15%; */

  gap: 2%;

  list-style: none;
}

.AboutUs-background img {
  width: 35%;
  aspect-ratio: 1;
  border-radius: 38.77px;

  object-fit: cover;

  background-color: rgba(0, 0, 0, 0.2);

  filter: drop-shadow(0px 3px 30px rgba(0, 0, 0, 0.2));
}

.AboutUs-background .inf-area {
  display: flex;
  flex-direction: column;
  width: 65%;
  transition-duration: 100ms;
}

.AboutUs-background .inf-area .title {
  font-family: "Rubik-Bold";
  font-size: 22px;
  color: #17161a;
}

.AboutUs-background .inf-area p {
  font-family: "Rubik-Regular";
  font-size: 14px;
  color: #6a696c;
}

.AboutUs-background .inf-area #NavLink {
  font-family: "Rubik-Bold";
  font-size: 18px;
  color: white;

  width: min-content;

  padding: 4px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #17161a;
  border-radius: 8px;
  border: none;

  transition-duration: 250ms;
}

.AboutUs-background .inf-area #NavLink:hover {
  background-color: #6a696c;
  filter: drop-shadow(0px 3px 30px rgba(0, 0, 0, 0.2));
}

.AboutUs-background .paragraph {
  font-family: "Rubik-Regular";
  font-size: 17px;
  color: #6a696c;
  word-wrap: break-word;

  margin-top: 25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 900px) {
  .AboutUs-background img {
    display: none;
  }

  /* .AboutUs-background {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    } */

  .AboutUs-background .inf-area {
    width: 100%;
  }

  .AboutUs-background .inf-area .title {
    text-align: center;
  }

  .AboutUs-background .inf-area #NavLink {
    width: auto;
  }
}