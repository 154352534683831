.news-background {
  width: 100%;
  max-width: 320px;
  height: auto;

  background-color: white;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  border-radius: 22px;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  transition-duration: 200ms;

  list-style: none;

  text-align: center;
}

.news-background .photo {
  width: 100%;
  aspect-ratio: 1;
}

.news-background div {
  display: flex;
  flex-direction: row;

  justify-content: space-around;

  font-size: 12px;

  margin-top: 2%;
}

.news-background li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  text-align: left;
}

.news-background .title {
  margin: 5%;
  margin-left: 8%;
  margin-right: 8%;

  color: #17161A;

  font-size: 18px;
}