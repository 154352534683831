.Auction-background {
  width: 100%;
  max-width: 290px;
  height: auto;
  /* aspect-ratio: 58/100; */

  background-color: white;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  border-radius: 22px;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  transition-duration: 200ms;
}

.Auction-background .photo-box {
  width: 100%;

  aspect-ratio: 1;
}

.Auction-background .information-box {
  height: 44%;
  width: 94%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  text-align: left;

  list-style: none;

  padding: 3%;

  overflow: hidden;

  gap: 15px;
}

.Auction-background .Status {
  position: absolute;

  margin-top: 10px;
  margin-left: 10px;
  width: auto;
  height: 16.2px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;

  border-radius: 60px;
  background-color: #17161a;

  font-size: 10px;
  color: white;

  font-family: "Rubik-Medium";

  white-space: nowrap;

  gap: 5px;
  padding-right: 3.1px;

  text-align: center;
}
.Auction-background .Status .Ball {
  width: 10px;
  height: 10px;
  margin-left: 3.1px;

  border-radius: 60px;
  background-color: #38b000;
}

.Auction-background .information-box #title {
  font-family: "Rubik-Medium";
  font-size: 18px;

  color: #17161a;
}

.Auction-background .information-box #owner,
#remaining-header {
  font-size: 14px;

  color: #6a696c;
}

.Auction-background .information-box #offer-count {
  font-family: "Rubik-Regular";
  font-size: 14px;

  color: #38b000;
}

.Auction-background .information-box .prices {
  display: inline-flex;
  flex-direction: row;

  justify-content: flex-end;
  vertical-align: middle;
  align-items: flex-end;
}
.Auction-background .information-box #price {
  font-family: "Rubik-Bold";
  font-size: 22px;

  color: #38b000;
  display: inline-flex;
  align-items: baseline;
  gap: 10px;
}
.Auction-background .information-box #old-price {
  font-size: 14px;
  text-decoration: line-through;

  font-family: "Rubik-Medium";
  color: rgba(0, 0, 0, 0.4);
}

.Auction-background .information-box #remaining {
  font-family: "Rubik-Bold";
  font-size: 22px;

  color: #17161a;
}

@media screen and (max-width: 490px) {
  .Auction-background .information-box #title {
    font-size: 14px;
  }
  .Auction-background .information-box #owner {
    font-size: 12px;
  }
  .Auction-background .information-box #offer-count {
    font-size: 12px;
  }
  .Auction-background .information-box #price {
    font-size: 19px;
  }
  .Auction-background .information-box #old-price {
    font-size: 12px;
  }
  .Auction-background .information-box #remaining {
    font-size: 20px;
  }
  .Auction-background .information-box #owner,
  #remaining-header {
    font-size: 12px;
  }
}
