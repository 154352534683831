.Error404Page {
  width: 60%;
  height: auto;

  margin-top: 8%;
  margin-bottom: 8%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
}
.Error404Page li {
  font-family: "Rubik-Medium";
  font-size: 2.5vw;

  white-space: nowrap;
  list-style: none;
}
.Error404Page .Icon {
  margin-bottom: 4%;

  width: 80%;
  height: auto;
  aspect-ratio: 100/37;
}
.Error404Page .MainMenu-button {
  font-family: "Rubik-Bold";
  width: 18%;
  height: auto;
  aspect-ratio: 5/1.4;

  min-width: 150px;

  margin-top: 1%;

  font-size: 25px;
  white-space: nowrap;
  color: white;

  background: #17161a;
  border-radius: 1.2vw;
  border-width: 0;

  transition-duration: 100ms;
}
.Error404Page .MainMenu:hover {
  background-color: #d02224;
  transition-duration: 100ms;
}
