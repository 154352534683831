.group-auction-page-background {
  margin: 50px;
  margin-left: 10%;
  margin-right: 10%;

  display: flex;
  flex-direction: column;

  gap: 5%;
}

.group-auction-page-background strong {
  font-family: "Rubik-Bold";
  color: #17161a;
}

.group-auction-page-background .explanation {
  margin-top: 25px;
  margin-right: 20%;
  font-family: "Rubik-Regular";
  font-size: 17px;
  color: #6a696c;
}

.group-auction-page-background .top-area {
  display: flex;
  flex-direction: row;
  gap: 25px;

  width: 100%;
}

.group-auction-page-background .informations {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 3;

  align-self: flex-start;
  top: 132px;

  width: 100%;
}

.group-auction-page-background .informations .status {
  padding: 6px;
  padding-top: 4px;
  padding-bottom: 4px;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 60px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 5px;

  font-family: "Rubik-Regular";
  font-size: 14px;
}

.group-auction-page-background .informations .status .status-balloon {
  aspect-ratio: 1;
  width: 13px;
  height: 13px;

  border-radius: 360px;
}

.group-auction-page-background .informations .info-box {
  flex: 2;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 38.77px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 25px;

  gap: 3px;
}

.group-auction-page-background .informations .info-box-flex {
  flex: 1;
}

.group-auction-page-background .informations .info-title {
  font-family: "Rubik-Medium";
  font-size: 17px;
  color: #6a696c;
}

.group-auction-page-background .informations .description-text {
  font-family: "Rubik-Bold";
  font-size: 20px;

  color: #17161a;
}

.group-auction-page-background .title {
  font-family: "Rubik-Bold";
  font-size: 27px;

  margin-top: 15px;
}

.group-auction-page-background .main-image {
  width: 100%;
  aspect-ratio: 1;
  flex: 1;
  filter: drop-shadow(0px 1.5px 15px rgba(0, 0, 0, 0.2));
  border-radius: 38.77px;

  align-self: flex-start;
}

.items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  column-gap: 20px;
  row-gap: 20px;

  margin-top: 50px;

  /* margin-top: 100px; */
}

@media screen and (max-width: 1350px) {
  .group-auction-page-background {
    margin-left: 2%;
    margin-right: 2%;
  }

  .group-auction-page-background .top-area {
    flex-direction: column;
  }

  .group-auction-page-background .main-image {
    width: 40%;
    min-width: 250px;
  }

  .group-auction-page-background .explanation {
    margin-right: 0;
  }

  .group-auction-page-background .informations .info-box-flex {
    flex: 2;
  }
}

@media screen and (max-width: 950px) {
  .group-auction-page-background .informations .info-box-flex {
    flex: 1;
  }
}

@media screen and (max-width: 650px) {
  .group-auction-page-background .informations .info-box-flex {
    flex: 0;
  }
}