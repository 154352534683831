@font-face {
  font-family: "Rubik-Bold";
  src: local("Rubik-Bold"), url("./Rubik-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"), url("./Rubik-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Rubik-Medium";
  src: local("Rubik-Medium"), url("./Rubik-Medium.ttf") format("truetype");
  font-weight: bold;
}
