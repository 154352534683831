.auctions-background {
  width: 84%;

  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.auctions-background .not-found {
  font-family: "Rubik-Medium";
  color: #6a696c;
  font-size: 18px;
}

.auctions-background .options-area {
  width: 100%;

  position: sticky;
  top: 88px;
  background-color: white;

  z-index: 800;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding-bottom: 25px;
  padding-top: 25px;

  margin-bottom: 25px;

  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.auctions-background .options-area .filter-buttons {
  display: flex;
  flex-direction: row;

  gap: 5px;

  width: 100%;
}

.auctions-background .filter-buttons button {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  white-space: nowrap;

  padding: 4px;
  padding-left: 15px;
  padding-right: 15px;

  gap: 2px;

  border: none;
  border-radius: 12.72px;

  transition-duration: 100ms;

  font-size: 16px;
  font-family: "Rubik-Regular";
}

.auctions-background .filter-buttons button div {
  display: flex;
  align-items: center;
  fill: #6a696c;
}

.auctions-background .filter-buttons button.selected {
  background-color: #d02224;
  font-family: "Rubik-Medium";
  color: white;
}

.auctions-background .filter-buttons button.selected div {
  fill: white;
}

.auctions-background .Icon {
  transition-duration: 100ms;
}

.auctions-background .options-area .search {
  min-width: 250px;
}

.auctions-background .options-area .search-bar button {
  position: absolute;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;
}

.auctions-background .options-area .search-bar input {
  height: 28px;
  width: calc(100% - 28px);

  font-family: "Rubik-Medium";

  border-radius: 8px;
  border: none;

  padding-left: 28px;
  padding-right: 0;

  background: #f4f4f4;
  color: #17161a;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  text-align: left;

  transition-duration: 150ms;
}

.auctions-background .options-area .search-bar input:focus-visible {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: none !important;

  outline: none;

  transition-duration: 250ms;
}

.auctions-background .items {
  width: 92%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-left: 4%;
  margin-right: 4%;

  column-gap: 20px;
  row-gap: 20px;
}

.auctions-background .more {
  margin-top: 25px;

  font-family: "Rubik-Bold";
  font-size: 19px;

  color: white;
  background-color: #d02224;

  border: none;
  border-radius: 12.72px;

  padding: 6px;
  padding-left: 20px;
  padding-right: 20px;

  transition-duration: 150ms;
}

.auctions-background .more:hover {
  background-color: #961618;
}

@media screen and (max-width: 1090px) {
  .auctions-background .options-area {
    display: flex;
    flex-direction: column;

    margin-bottom: 25px;
    align-items: flex-start;

    gap: 25px;

    transition-duration: 150ms;
  }
}

@media screen and (max-width: 750px) {
  .auctions-background {
    width: 94%;

    margin-left: 3%;
    margin-right: 3%;
  }

  .auctions-background .options-area .filter-buttons {
    display: grid;
    grid-template-columns: 47% 50%;

    column-gap: 10px;
    row-gap: 15px;

    text-align: left;
  }

  .auctions-background .options-area .filter-buttons button {
    align-items: center;
    justify-content: left;
    width: 100%;
  }

  .auctions-background .options-area .search {
    min-width: 100%;
  }
}