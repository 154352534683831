.title-photo {
  position: relative;
  width: 100%;
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-photo span {
  position: absolute;
  top: 50%;
  left: 8%;

  font-family: "Rubik-Bold";
  font-size: 40px;

  color: white;

  transition-duration: 250ms;

  transform: translate(0, -50%);
}