.urgent-notification-background {
    position: sticky;
    top: 87px;
    z-index: 500;
    background-color: #D02224;
    padding-top: 10px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.urgent-notification-background span {
    font-family: "Rubik-Medium";
    color: white;

    text-align: center;

    flex: 1;

    padding-left: 39px;
}
.urgent-notification-background button {
    text-align: center;

    width: 24px;

    margin-right: 15px;

    display: grid;
    place-content: center; 

    border: none;
    outline: none;

    background-color: transparent;
}