.HowToBid-background {
  margin-top: 50px;
}

.HowToBid-background .options {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 55px;
}

.HowToBid-background .button-row {
  display: flex;
  flex-direction: row;
  height: 100%;

  margin-left: 8%;
  margin-right: 8%;
}

.HowToBid-background .button-row button {
  width: 100%;
  height: 100%;

  font-family: "Rubik-Bold";
  font-size: 28px;

  border-radius: 12.72px 12.72px 0px 0px;

  border: none;
  z-index: 5;

  transition-duration: 250ms;
}
.HowToBid-background .line {
  width: 100%;
  height: 1.5px;

  position: absolute;

  background-color: #00000033;

  z-index: 0;
}

.HowToBid-background .viewport {
  width: 100%;
  height: auto;

  overflow: hidden;
}

.HowToBid-background .content {
  width: 200%;

  margin-top: 100px;
  margin-bottom: 100px;

  display: flex;
  flex-direction: row;

  transition-duration: 250ms;
}

/* contents */
.HowToBid-background .content div {
  width: 100%;

  display: flex;
  flex-direction: row;

  list-style: none;
}
.HowToBid-background .content .title {
  display: flex;
  flex-direction: row;

  font-family: "Rubik-Medium";
  font-size: 22px;
  color: #d02224;
}
.HowToBid-background .content .title li {
  padding-left: 5px;
  padding-bottom: 6px;
  color: #17161a;
}
.HowToBid-background .content .paragraph {
  display: flex;
  flex-direction: row;

  font-family: "Rubik-Regular";
  font-size: 15px;
  color: #6a696c;
}
/* contents */

/* content 0 */
.HowToBid-background .content #content-0 #image-area {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.HowToBid-background .content #content-0 #inf-area {
  width: 42%;

  display: flex;
  flex-direction: column;

  margin-right: 8%;
  gap: 40px;
}
.HowToBid-background .content #content-0 #inf-area div {
  display: flex;
  flex-direction: column;
}
.HowToBid-background .content #content-0 #image-area img {
  width: 70%;
  height: auto;
  margin-right: 10%;
  margin-left: 20%;
  aspect-ratio: 570/371;
}
/* content 0 */

@media screen and (max-width: 900px) {
  .HowToBid-background .options {
    height: 40px;
  }
  .HowToBid-background .button-row {
    margin: 0;
  }
  .HowToBid-background .button-row button {
    font-size: 18px;
  }
  .HowToBid-background .button-row #button-0 {
    border-radius: 0px 10px 0px 0px;
  }
  .HowToBid-background .button-row #button-1 {
    border-radius: 10px 0px 0px 0px;
  }
  .HowToBid-background .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  /* content - 0 */
  .HowToBid-background .content #content-0 #image-area {
    display: none;
  }
  .HowToBid-background .content #content-0 #inf-area {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  /* content - 0 */
}
