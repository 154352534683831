.newscast-viewport {
  width: 84%;

  margin-right: 8%;
  margin-left: 8%;

  margin-top: 50px;
  margin-bottom: 50px;

  display: flex;

  gap: 4%;
}

.newscast-viewport .not-found {
  color: #6a696c;
  font-family: "Rubik-Medium";
  font-size: 18px;
  align-self: center;
}

.newscast-viewport .newscast-area {
  width: 76%;

  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
}

.newscast-viewport .news {
  height: min-content;
  width: 100%;

  display: grid;
  justify-items: center;

  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  row-gap: 20px;
  column-gap: 20px;
}

.page-buttons {
  margin-top: 20px;
  width: 100%;
  height: 30px;

  gap: 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  color: white;

  font-family: "Rubik-Bold";
}

.page-buttons button {
  font-family: "Rubik-Bold";
  font-size: 18px;
  border: none;

  border-radius: 10px;

  padding-left: 20px;
  padding-right: 20px;

  color: white;
  background-color: #17161a;
  transition-duration: 200ms;
}

.page-buttons button:hover {
  transition-duration: 200ms;
  background-color: gray;
}

.page-buttons div {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #17161a;

  width: 30px;
  height: 100%;

  border-radius: 10px;
}

@media screen and (max-width: 1100px) {
  .newscast-viewport {
    width: 96%;

    margin-right: 2%;
    margin-left: 2%;
  }

  .newscast-viewport .newscast-area {
    width: 56%;
  }
}

@media screen and (max-width: 750px) {
  .newscast-viewport {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 0px;
  }

  .newscast-viewport .newscast-area {
    width: 100%;
  }

  .newscast-viewport .news {
    height: min-content;
    width: 100%;

    display: grid;
    justify-items: center;

    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

    row-gap: 25px;
    column-gap: 25px;
  }
}