.my-auction {
  width: 100%;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 38.7733px;

  display: flex;
  flex-direction: row;

  z-index: 10;
}

.my-auction img {
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  border-radius: 38.7733px;

  aspect-ratio: 1;
  height: 250px;

  z-index: 20;
}

.my-auction .profile {
  background-color: #b3b3b3;
  aspect-ratio: 1;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 360px;
}

.my-auction .item {
  border: 2px dashed;
  border-radius: 22px;

  padding: 10px;

  padding-left: 8px;
  padding-right: 8px;

  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
  flex-shrink: 1;
}

.my-auction .item-scrollable {
  border: 2px dashed;
  border-radius: 22px;

  padding: 10px;

  padding-left: 8px;
  padding-right: 8px;

  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-auction .informations {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  align-items: stretch;
  justify-content: stretch;

  gap: 15px;
  width: 50%;
}

.for-rows {
  display: flex;
  flex-direction: row !important;
  gap: 5px !important;
}

.for-rows div {
  display: flex;
  flex-direction: column;
}

.my-auction .gray {
  border-color: #b3b3b3;
}
.my-auction .orange {
  border-color: #e85d04;
}

.my-auction .thin-text {
  font-family: "Rubik-Regular";
  font-size: 14px;
  color: #17161a;
}

.my-auction .bold-text {
  font-family: "Rubik-Medium";
  font-size: 14px;
  color: #17161a;
}

.my-auction .thin-text-orange {
  font-family: "Rubik-Regular";
  font-size: 14px;
  color: #e85d04;
}

.my-auction .bold-text-orange {
  font-family: "Rubik-Medium";
  font-size: 14px;
  color: #e85d04;
}

.my-auction .my-auction-content {
  position: relative;

  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
  width: 100%;

  background-color: white;

  border-radius: 38.7733px;
}

.my-auction .expand {
  position: absolute;

  bottom: 0;
  left: 50%;

  width: 40px;
  aspect-ratio: 1;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 360px;

  border: none;
  outline: none;

  transition-duration: 100ms;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
}

.my-auction .bottom-bar {
  position: absolute;

  width: calc(100%);
  left: 0;

  bottom: -50px;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  border-radius: 0px 0px 38.7733px 38.7733px;

  z-index: -10;
}

.my-auction .bottom-bar-viewport {
  margin: 20px;
  margin-top: 45px;
  margin-bottom: 0px;
  padding: 5px;

  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* .my-auction .bottom-bar-viewport::-webkit-scrollbar {
  display: none;
} */

.my-auction .bottom-bar-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: max-content;
  overflow: auto;

  margin-bottom: 10px;
  gap: 10px;
}

@media screen and (max-width: 750px) {
  .my-auction {
    width: 100%;

    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
    background: #ffffff;
    border-radius: 38.7733px;

    display: flex;
    flex-direction: column;
  }

  .my-auction .my-auction-content {
    flex-direction: column;
    width: calc(100% - 20px);
  }

  .my-auction .informations {
    width: 100%;
  }

  .my-auction img {
    height: auto;
    width: 100%;
  }

  .my-auction .expand {
    bottom: -10px;
    /* left: auto; */
    /* right: -10px; */
  }

  .my-auction .bottom-bar {
    width: 100%;
  }
}
