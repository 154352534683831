* {
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik-Regular", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100px;
}

#page {
  animation: 250ms page-keyframes forwards;
  animation-iteration-count: 1;
}

@keyframes page-keyframes {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }

  to {
    opacity: 100;
  }
}

a {
  transition-duration: 100ms;

  padding-left: 12.5px;
  padding-right: 12.5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-decoration: none;
}

a:hover {
  transition-duration: 100ms;
  color: #6a696c;
}

.active {
  color: #d02224 !important;
  transition-duration: 1500ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* Fire Engine Red */
  transition-duration: 250ms;
  background-color: #d02224;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition-duration: 250ms;
  background-color: rgba(174, 0, 02, 1);
}

/* Place holders */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #00000033;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #00000033;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #00000033;
}

a:active,
button:active {
  transition-duration: 150ms;
  transform: translate(0, 2px);
}