.Contact-background {
  width: 100%;

  list-style: none;
}

.Contact-background .Icon {
  padding-right: 8px;
}

.Contact-background .top {
  width: 100%;
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  gap: none;
}

.Contact-background textarea::placeholder {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.Contact-background textarea {
  position: relative;

  padding-top: 10px;
  padding-bottom: 10px;
}

.Contact-background .contact-form {
  width: 32%;
  background-color: white;
  border-radius: 38px 80px 80px 38px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  margin-right: 10%;
  margin-left: 2%;

  padding-top: 4%;
  padding-bottom: 4%;

  padding-right: 3%;
  padding-left: 3%;
}

.Contact-background .contact-area {
  width: 32%;
  background-color: white;
  border-radius: 80px 38px 38px 80px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;

  padding-top: 4%;
  padding-bottom: 4%;

  margin-right: 2%;
  margin-left: 10%;

  padding-right: 3%;
  padding-left: 3%;
}

.Contact-background .subtitle {
  font-family: "Rubik-Medium";
  font-size: 18px;
  margin-bottom: 8px;
}

.Contact-background .title {
  font-family: "Rubik-Bold";
  font-size: 28px;

  margin-bottom: 22px;
}

.Contact-background .contact-area .mail-tel {
  background-color: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 14px;

  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: none;

  margin-bottom: 20px;
}

.Contact-background .contact-area .mail-tel div {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.Contact-background .contact-area .other-contact-infos {
  width: 100%;
  gap: 5%;
  display: flex;
  flex-direction: row;
}

.Contact-background .contact-area .other-contact-infos .sub {
  width: 47.5%;
  display: flex;
  flex-direction: column;
}

.Contact-background .area {
  background-color: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 14px;

  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-around;

  margin-bottom: 25px;
}

.Contact-background a {
  font-family: "Rubik-Regular";
  font-size: 16px;
  color: #17161a;
}

.Contact-background iframe {
  flex: 2;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-color: white;
  border: none;
}

@media screen and (max-width: 1290px) {
  .Contact-background .contact-area .mail-tel {
    flex-direction: column;
    gap: 8px;
  }
}

@media screen and (max-width: 1150px) {
  .Contact-background .contact-area .other-contact-infos {
    gap: 0;
    flex-direction: column;
  }

  .Contact-background .contact-area .other-contact-infos .sub {
    width: 97.5%;
    display: flex;
    flex-direction: column;
  }

  .Contact-background .contact-area .other-contact-infos .sub {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .Contact-background .top {
    flex-direction: column;
    gap: 30px;
  }

  .Contact-background .contact-area {
    width: 72%;
    border-radius: 38px;

    margin-right: 4%;
    margin-left: 4%;

    padding: 10%;
  }

  .Contact-background .contact-form {
    width: 72%;
    border-radius: 38px;

    margin-right: 4%;
    margin-left: 4%;

    padding: 10%;
  }

  .Contact-background .contact-area .mail-tel {
    flex-direction: row;
    gap: none;
  }
}

@media screen and (max-width: 570px) {
  .Contact-background .contact-area .mail-tel {
    flex-direction: column;
    gap: 8px;
  }
}

/* input bars */

.Contact-background .InputBars {
  margin-top: 25px;
  margin-bottom: 0;
  gap: 20px;

  font-family: "Rubik-Medium";

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Contact-background .InputBar {
  display: flex;
  flex-direction: column;
}

.Contact-background .InputBar {
  gap: 8px;
}

.Contact-background .InputBars .Input {
  min-height: 45px;

  gap: 10px;
  display: flex;
  vertical-align: middle;

  border-width: 0;
  background: #f4f4f4;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12.72px;
}

.Contact-background .InputBars .Input:focus {
  outline: auto;
}

.Contact-background .InputBars .Input .Icon {
  position: static;
  padding-top: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.Input {
  border-radius: 12.72px;
}

.Contact-background .InputBars .Input input,
.Contact-background .InputBars .Input textarea {
  position: initial;

  width: 100%;
  border-radius: 0 15px 15px 0;
  border-width: 0;

  font-family: "Rubik-Medium";
  color: #17161a;

  background-color: transparent;
}

.Contact-background .InputBars .Input textarea {
  resize: vertical;
  min-height: 45px;
}

.Contact-background .InputBars .Input input:focus,
.Contact-background .InputBars .Input textarea:focus {
  outline: none;
}

/* Buttons */
.Contact-background .Buttons {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.Contact-background .Button {
  font-family: "Rubik-Bold";
  font-size: 16px;

  width: 100%;

  height: 35px;

  border-radius: 12.72px;

  border: none;

  transition-duration: 250ms;
}

.Contact-background .Button:hover {
  scale: 1.025;
  transition-duration: 250ms;
}

.Contact-background #Red {
  background: #d02224;
  color: white;
}

.Contact-background #Outlined {
  background: white;
  color: #d02224;

  border: 2.5px solid #d02224;
}