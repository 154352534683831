.newspage-viewport {
  width: 84%;

  margin-right: 8%;
  margin-left: 8%;

  margin-top: 50px;
  margin-bottom: 50px;

  display: flex;

  gap: 4%;
}

.newspage-viewport .news-content {
  width: 66%;

  padding: 2%;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 38.77px;
}

.news-content img {
  width: 100%;
  aspect-ratio: 1140/300;
}

.news-content h1 {
  font-family: "Rubik-Bold";
  font-size: 24px;

  color: #17161a;
}

.news-content h2 {
  font-family: "Rubik-Medium";
  font-size: 19px;

  color: #17161a;
}

.news-content p {
  font-family: "Rubik-Regular";
  font-size: 15px;
  color: #6a696c;
}

.newspage-viewport .news-content .news-inf-area {
  display: flex;
  flex-direction: row;

  gap: 20px;

  margin-top: 25px;
  margin-bottom: 15px;
}

.newspage-viewport .news-content .news-inf-area div {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 3px;

  font-family: "Rubik-Regular";
  font-size: 15px;
  color: #17161a;
  white-space: nowrap;
}

.newspage-viewport .comment-bar {
  width: 100%;
  height: 50px;
  display: flex;
}

.newspage-viewport .comment-bar div {
  position: absolute;
  height: 50px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;
}

.newspage-viewport .comment-bar input {
  height: auto;
  width: 100%;

  font-family: "Rubik-Medium";

  border-radius: 8px;
  border: none;

  padding-left: 28px;
  padding-right: 0;

  background: #f4f4f4;
  color: #17161a;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  text-align: left;

  transition-duration: 150ms;

  /* word-break: break-all; */
}

.newspage-viewport .comment-bar input:focus-visible {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: none !important;

  outline: none;

  transition-duration: 250ms;
}

.newspage-viewport .comment-buttons {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 15px;

  margin-top: 20px;
  margin-bottom: 35px;
}

.newspage-viewport .comment-buttons button {
  border-radius: 12.72px;
  background-color: white;
}

.newspage-viewport .comment-buttons #send {
  font-family: "Rubik-Bold";
  font-size: 20px;
  padding-left: 35px;
  padding-right: 35px;

  border: 2.5px solid #6a696c;
  color: #6a696c;
}

.newspage-viewport .comment-buttons #like {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2.5px solid #d02224;

  aspect-ratio: 1;
}

.newspage-viewport .not-found {
  color: #6a696c;
  font-family: "Rubik-Medium";
  font-size: 14px;
  align-self: center;
}

.newspage-viewport .comments {
  display: flex;
  flex-direction: column;

  gap: 25px;
}

.newspage-viewport .comments div {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.newspage-viewport .comments .comment {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 8px;
}

.newspage-viewport .comments .comment .infs {
  display: flex;
  flex-direction: column;

  gap: 5px;
}

.newspage-viewport .comments .comment h3 {
  margin: 0;

  font-size: 13px;
  font-family: "Rubik-Medium";

  color: #17161a;
}

.newspage-viewport .comments .comment p {
  margin: 0;

  font-size: 13px;
  font-family: "Rubik-Medium";

  word-break: break-all;

  color: #6a696c;

  text-overflow: ellipsis;
}

.newspage-viewport .comments .comment img {
  height: 50px;
  width: 50px;

  border-radius: 360px;
}

@media screen and (max-width: 1100px) {
  .newspage-viewport {
    width: 96%;

    margin-right: 2%;
    margin-left: 2%;
  }

  .newspage-viewport .news-content {
    padding: 4%;
    padding-bottom: 20px;
    padding-top: 20px;

    width: 92%;
  }

  .newspage-viewport .news-content .news-inf-area {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }
}

@media screen and (max-width: 750px) {
  .newspage-viewport {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 20px;
  }

  .newspage-viewport .news-content {
    width: 100%;

    padding: 0;

    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
}