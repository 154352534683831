.MainTitle {
  width: 100%;
  height: 100%;

  background: #ffffff;
  border-radius: 22px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  list-style: none;
  padding: 5%;

  transition-duration: 250ms;
}

.MainTitle li {
  font-size: 25px;
  font-family: "Rubik-Bold";
}

.MainTitle span {
  font-size: 15px;
  padding-top: 5%;
  font-family: "Rubik-Regular";
  text-align: center;
}

.MainTitle div {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-right: 24px;
}

@media screen and (min-width: 900px) {
  .MainTitle {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}