.news-search-area {
  width: 15%;
  min-width: 250px;

  height: min-content;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 30px;

  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 2.5%;
  padding-right: 2.5%;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 22px;
  background-color: white;

  transition-duration: 250ms;
}

.news-search-area .logo {
  width: 60%;
  height: auto;
}

.news-search-area .logo img {
  width: 100%;
  aspect-ratio: 1;
}

.news-search-area .news-list {
  width: 100%;
  height: min-content;

  display: flex;
  flex-direction: column;

  gap: 10px;
}

.news-search-area .news-list-2 {
  width: 100%;
  height: min-content;

  display: flex;
  flex-direction: column;

  gap: 10px;
}

.news-search-area .news-list li {
  display: flex;
  flex-direction: row;

  margin-bottom: 10px;

  width: 100%;
  align-items: center;
  justify-content: flex-start;

  list-style: none;

  font-size: 18px;

  gap: 10px;
}

.news-search-area .stroke {
  width: 2.5px;
  height: 22px;

  border-radius: 360px;
  background-color: #17161a;
}

.news-search-area .sub-news {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 14px;

  gap: 15px;

  color: #17161a;

  transition-duration: 250ms;
}

.news-search-area .sub-news:hover {
  color: gray;

  transition-duration: 250ms;
}

.news-search-area .not-found-news {
  color: #6a696c;
  font-family: "Rubik-Medium";
  font-size: 14px;
}

.news-search-area .sub-news img {
  width: 22%;
  aspect-ratio: 1;
  object-fit: cover;

  border-radius: 12px;
}

.news-search-area .sub-news hr {
  display: none;
}

.news-search-area .search-bar {
  width: 100%;
  display: flex;
}

.news-search-area .search-bar button {
  position: absolute;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;
}

.news-search-area .search-bar input {
  height: 28px;
  width: 100%;

  font-family: "Rubik-Medium";

  border-radius: 8px;
  border: none;

  padding-left: 28px;
  padding-right: 0;

  background: #f4f4f4;
  color: #17161a;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  text-align: left;

  transition-duration: 150ms;
}

.news-search-area .search-bar input:focus-visible {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: none !important;

  outline: none;

  transition-duration: 250ms;
}



/* responsives */
@media screen and (max-width: 1100px) {
  .news-search-area {
    width: 35%;
  }
}

@media screen and (max-width: 750px) {
  .news-search-area {
    width: 90%;

    padding-left: 5%;
    padding-right: 5%;

    box-shadow: none;
  }

  .news-search-area .logo {
    display: none;
  }
}