.Gallery-background {
  list-style: none;
}

.Gallery-background .safe-area {
  width: 84%;

  margin-right: 8%;
  margin-left: 8%;
}

.Gallery-background .gallery {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  margin-bottom: 75px;
}

.Gallery-background .not-found {
  color: #6a696c;
  font-family: "Rubik-Medium";
  font-size: 14px;
  align-self: center;
  margin-bottom: 25px;
}

.Gallery-background .page-number {
  color: #6a696c;
  display: flex;
  align-items: center;

  margin-top: 25px;
  margin-bottom: 25px;

  font-family: "Rubik-Bold";
  font-size: 20px;

  gap: 10px;
}

.Gallery-background .page-number div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Gallery-background button {
  background-color: transparent;
  border: none;

  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 600px) {
  .Gallery-background .safe-area {
    width: 96%;

    margin-right: 2%;
    margin-left: 2%;
  }

  .Gallery-background .gallery {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}