.my-auctions {
  display: flex;
  flex-direction: column;

  margin-left: 10px;
  margin-right: 10px;

  gap: 10px;

  z-index: 10;
}

.my-auctions .not-found {
  font-family: "Rubik-Medium";
  color: #6a696c;
  font-size: 18px;
  align-self: center;
}

.my-auctions-list {
  display: flex;
  flex-direction: column;

  gap: 30px;
  margin-top: 20px;
}

.my-auctions .search-bar {
  border-radius: 12.72px;

  border: 2px dashed #b3b3b3;
  width: calc(40% - 30px);
  height: 25px;
  outline: none;

  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;

  font-family: "Rubik-Regular";
  color: #17161a;
}

.my-auctions .search-bar::placeholder {
  font-family: "Rubik-Regular";
  color: #b3b3b3;
}

@media screen and (max-width: 1000px) {
  .my-auctions .search-bar {
    width: calc(100% - 30px);
  }

  .my-auctions {
    display: flex;
    flex-direction: column;

    margin-left: 5%;
    margin-right: 5%;

    gap: 10px;
  }
}