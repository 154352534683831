.my-account-background {
  margin: 50px;
  margin-left: 8%;
  margin-right: 8%;

  display: flex;
  flex-direction: row;

  gap: 10px;

  transition-duration: 100ms;
}

.my-account-background .options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 20px;
}

.my-account-background .link-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 5px;

  color: #6a696c;

  padding: 8px;
  padding-left: 12.5px;
  padding-right: 12.5px;

  border: none;
  outline: none;

  background-color: transparent;

  font-family: "Rubik-Regular";
  font-size: 16px;

  transition-duration: 100ms;

  border-radius: 12.72px;
}

.my-account-background .link-title {
  color: #6a696c;
  font-family: "Rubik-Medium";
}

.my-account-background .active {
  color: #ffffff !important;
  background-color: #d02224;
}

.my-account-background .content {
  flex: 15;
  margin-bottom: 20px;
}

.my-account-background hr {
  display: none;
}
@media screen and (max-width: 1200px) {
  .my-account-background {
    margin: 0;
    margin-left: 0;
    margin-right: 0;

    flex-direction: column;
    gap: 0;
  }

  .my-account-background .link-title {
    display: none;
  }

  .my-account-background .options {
    position: sticky;
    top: 88px;
    flex-direction: row;

    padding-bottom: 10px;
    padding-top: 10px;

    /* mask-image: linear-gradient(90deg, #000 95%, transparent); */

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    overflow-y: scroll;

    gap: 10px;

    margin: 0;

    padding-left: 10px;
    padding-right: 10px;

    z-index: 900;

    background-color: white;
  }
  .my-account-background .options::-webkit-scrollbar {
    display: none;
  }
  .my-account-background .link-button {
    white-space: nowrap;
  }
  .my-account-background hr {
    position: sticky;
    top: 146px;
    z-index: 900;

    display: block;
    width: 100%;
    height: 1px;
    border-width: 0;
    background-color: #6a696c;
    margin-top: 0;
  }
}
