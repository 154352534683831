.feedback-background {
  position: fixed;

  left: 50%;
  justify-content: center;
  align-items: center;
  z-index: 8000;

  background-color: #ffffff;

  background: #ffffff;
  border-radius: 8px;

  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;

  font-family: "Rubik-Regular";

  transform: translate(-50%);

  font-size: 18px;

  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.9, -0.3, 0.1, 1.3);

  text-align: center;
}
