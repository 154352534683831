.AuthPageHierarchy {
  width: 45%;
  height: auto;

  max-width: 950px;

  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 35px;

  font-family: "Rubik-Bold";
}

.AuthPageHierarchy .AuthPageBackground {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  background-color: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 38.77px;

  padding-bottom: 38px;
  margin-bottom: 2%;
}

.AuthPageHierarchy .Title {
  font-size: 30px;
  font-weight: 700;
  color: #17161a;

  margin-top: 38px;
  margin-left: 38px;
}
.AuthPageHierarchy #middleTitle {
  margin-left: auto;
  margin-right: auto;
}

.AuthPageHierarchy .info {
  margin-top: 4px;
  color: rgba(106, 105, 108, 0.6);
  font-size: 9px;
}

.AuthPageHierarchy .InputBars {
  margin-left: 38px;
  margin-right: 38px;
  margin-top: 25px;
  margin-bottom: 0;
  gap: 20px;

  font-family: "Rubik-Medium";

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.AuthPageHierarchy .InputBar {
  display: flex;
  flex-direction: column;
}
.AuthPageHierarchy .InputBar {
  gap: 8px;
}
.AuthPageHierarchy .InputBars .Input {
  min-height: 45px;

  gap: 10px;
  display: flex;
  vertical-align: middle;

  border-width: 0;
  background: #f4f4f4;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12.72px;
}
.AuthPageHierarchy .InputBars .Input:focus {
  outline: auto;
}

.AuthPageHierarchy .InputBars .Input .Icon {
  position: static;
  padding-top: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}
.Input {
  border-radius: 12.72px;
}
.AuthPageHierarchy .InputBars .Input input {
  position: initial;

  width: 100%;
  border-radius: 0 15px 15px 0;
  border-width: 0;

  font-family: "Rubik-Medium";
  color: #17161a;

  background-color: transparent;
}
.AuthPageHierarchy .InputBars .Input input:focus {
  outline: none;
}

.AuthPageBackground .InputBarsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.InputBarsRow .InputBar {
  width: 47.5%;
}

.AuthPageHierarchy .ForgotPassword {
  color: rgba(0, 0, 0, 0.2);
  font-size: 15px;

  margin-top: 10px;

  padding-right: 38px;
  margin-left: auto;

  text-align: right;
  transition-duration: 250ms;
}
.AuthPageHierarchy .ForgotPassword:hover {
  color: rgba(0, 0, 0, 0.6);
  transition-duration: 250ms;
}

.AuthPageBackground .Buttons {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.AuthPageHierarchy .Button {
  font-family: "Rubik-Bold";
  font-size: 18px;

  height: 44px;

  border-radius: 12.72px;

  margin-right: 38px;
  margin-left: 38px;

  border: none;

  transition-duration: 250ms;
}
.AuthPageHierarchy .Button:hover {
  scale: 1.025;
  transition-duration: 250ms;
}

.AuthPageBackground #Black {
  background: #17161a;
  color: white;
}
.AuthPageBackground #Red {
  background: #d02224;
  color: white;
}
.AuthPageBackground #Outlined {
  background: transparent;
  color: #17161a;

  border: 2.5px solid #17161a;
}
.AuthPageBackground #Outlined:disabled {
  color: #6a696c;
  border: 2.5px solid #6a696c;
}
.AuthPageBackground #Outlined:disabled:hover {
  scale: 1;
}

.AuthPageHierarchy .Information {
  margin-left: 8%;
  margin-right: 8%;
  font-size: 10px;
  text-align: center;

  color: rgba(106, 105, 108, 1);
}

.AuthPageHierarchy .Information a {
  padding: 0;
  color: #4183c4;
}

.AuthPageHierarchy .Text {
  color: #17161a;
  font-family: "Rubik-Medium";
  font-size: 16px;
  margin-top: 35px;
  margin-bottom: 15px;
  margin-left: 38px;
  margin-right: 38px;
}

.AuthPageHierarchy .Paragraph {
  list-style: none;

  color: #6a696c;
  font-family: "Rubik-Regular";
  font-size: 15px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-left: 38px;
  margin-right: 38px;
  margin-top: 40px;
  margin-bottom: 25px;
}

.AuthPageHierarchy .Paragraph .Title {
  font-family: "Rubik-Medium";
  font-size: 15px;
  font-weight: 700;
  color: #17161a;

  margin: 0;
  margin-bottom: 15px;
}
.AuthPageHierarchy .Paragraph .Title a {
  font-size: 16px;
  font-family: "Rubik-Bold";
  padding: 0;
}

.AuthPageHierarchy .Paragraph div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

@media screen and (max-width: 1150px) {
  .AuthPageHierarchy {
    width: 65%;
  }
}
@media screen and (max-width: 900px) {
  .AuthPageHierarchy {
    width: 75%;
  }
}
@media screen and (max-width: 550px) {
  .AuthPageHierarchy {
    width: 95%;
  }
}
