.ContactBar {
  left: 0%;
  right: 0%;
  top: 0%;

  height: 44px;
  background: #17161a;
}

.Contact {
  transition-duration: 250ms;

  display: inline-flex;
  text-decoration: none;

  margin-left: 9%;
  height: 100%;

  left: 0;
}

.Text {
  transition-duration: 100ms;

  color: #f4f4f4;
  font-size: 12px;
  padding-left: 5px;
  vertical-align: middle;
  line-height: 100%;

  font-family: "Rubik-Regular";
}

.ContactBar-SocialMedia {
  justify-content: right;
  display: inline-flex;
  list-style: none;
  margin: auto;

  position: absolute;
  transition-duration: 250ms;
  right: 45.63%;
  height: 44px;
}

@media screen and (max-width: 1100px) {
  .ContactBar-SocialMedia {
    transition-duration: 250ms;
    right: 0;
  }
  .Contact {
    transition-duration: 250ms;
    margin: 0;
  }
}

@media screen and (max-width: 435px) {
  .Text {
    transition-duration: 100ms;
    font-size: 10px;
  }
  .ContactBar a {
    transition-duration: 100ms;

    padding-left: 4px;
    padding-right: 4px;
  }
}
