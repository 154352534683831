.MainMenu {
  background-color: white;
}

.MainMenu .line {
  width: 100%;
  height: 5px;
  background: #d02224;
}

.MainMenu .line-break {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.2);
}

.MainMenu .about-us {
  width: 70%;
  margin-right: 15%;
  margin-left: 15%;

  margin-top: 30px;
  margin-bottom: 80px;
}

@media screen and (min-width: 900px) {
  .MainMenu .line-break {
    background-color: transparent;
  }
}

@media screen and (max-width: 900px) {
  .MainMenu .about-us {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
