.InstitutionalLayout-content {
  display: flex;
  flex-direction: row;

  margin-right: 8%;
  margin-left: 8%;
  margin-top: 50px;

  margin-bottom: 50px;
}

.InstitutionalLayout-content .content {
  width: 80%;
}

.InstitutionalLayout-content .buttons {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  margin-top: 40px;
  padding-right: 35px;
}
.InstitutionalLayout-content .buttons .button {
  white-space: nowrap;

  gap: 5px;

  justify-content: flex-start;

  width: 90%;
  padding-left: 10%;

  border: none;
  background-color: white;
  color: #17161a !important;
  text-align: left;

  height: 55px;

  font-family: "Rubik-Medium";
  font-size: 16px;

  border-radius: 12.72px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  transition-duration: 100ms;
}
.InstitutionalLayout-content .buttons .active {
  background-color: #17161a;
  color: white !important;
}
.InstitutionalLayout-content .buttons .Icon {
  fill: #17161a;
}
.InstitutionalLayout-content .buttons .active .Icon {
  fill: white;
}

@media screen and (max-width: 1200px) {
  .InstitutionalLayout-content {
    flex-direction: column;

    gap: 20px;

    margin-right: 5%;
    margin-left: 5%;
  }
  .InstitutionalLayout-content .buttons {
    width: 95%;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 5%;
    margin-top: 0;
    padding-right: 0 !important;
  }
  .InstitutionalLayout-content .buttons .button {
    white-space: nowrap;

    width: 94%;
    padding-left: 3%;
    gap: 3%;
    font-size: 16px;
  }
  .InstitutionalLayout-content .content {
    width: 100%;
  }
}
