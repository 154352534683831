.are-you-sure-background {
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;

  z-index: 10001;

  animation: start-anim 250ms;

  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
}

.are-you-sure-background .content {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

  display: flex;
  flex-direction: column;

  animation: start-anim-box 250ms;

  gap: 15px;

  max-width: 500px;

  text-align: center;
}

@media screen and (max-width:500px) {
  .are-you-sure-background .content {
    max-width: calc(90% - 40px);
  }
}

.are-you-sure-background .content div {
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 10px;
}

.are-you-sure-background .content div button {
  flex: 1;
}

.are-you-sure-background #yes {
  outline: none;
  border: none;

  background: #60d394;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 15px;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  color: #ffffff;

  font-family: "Rubik-Medium";
  font-size: 15px;
}

.are-you-sure-background #no {
  outline: none;
  border: none;

  background: #ee6055;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 15px;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  color: #ffffff;

  font-family: "Rubik-Medium";
  font-size: 15px;
}

@keyframes start-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes start-anim-box {
  0% {
    transform: translate(0px, -50px);
  }

  100% {
    transform: translate(0px);
  }
}
